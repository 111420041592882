export default ({ request, requestSilent }) => ({

  getH5ByKey(params) {
    return request({
      url: '/goods/getGoodsMsgById',
      method: 'get',
      params
    })
  }
})
