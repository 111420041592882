<template>
  <div>
    <transition name="fade-transverse">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AxLayoutHeaderAside',

}
</script>


