export default ({ request, requestSilent }) => ({

  getGoodsHotList(params) {
    return request({
      url: '/goods/hot/list',
      method: 'get',
      params
    })
  },
  getInquiryGoodsList(params) {
    return request({
      url: '/goods/enquiry/list',
      method: 'get',
      params
    })
  },
  getImmedInquiryGoodsList(params) {
    return request({
      url: '/selfrun/commodity/management/list',
      method: 'get',
      params
    })
  }
})
