/**
 * Created by zhouyz on 2017/8/10.
 */
import getters from './getters.js'
console.log(window)
const state = {
  h5key: '',
  goodsInfo: {}
}
const mutations = {
  changeH5key(state, payload) {
    state.h5key = payload
  },
  changeGoodsInfo(state, payload) {
    state.goodsInfo = payload
  }
}
export default {
  state,
  mutations,
  getters
}
