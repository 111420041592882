import { Message } from 'element-ui'

const message = {}

/**
 * @description 显示成功信息
 * @param prompt 提示内容
 */
message.success = function(prompt) {
  Message({
    message: prompt,
    type: 'success',
    duration: 5 * 1000

  })
}

/**
 * @description 显示成功信息
 * @param prompt 提示内容
 * @param annexPrompt 如果提示内容为空，备用提示
 */
message.successTrim = function(prompt, annexPrompt) {
  Message({
    message: prompt == null || prompt.toString() === 'undefined' || prompt.toString().trim() === '' ? annexPrompt : prompt,
    type: 'success',
    duration: 5 * 1000
  })
}

/**
 * @description 显示警告信息
 * @param prompt 提示内容
 */
message.warning = function(prompt) {
  Message({
    message: prompt,
    type: 'warning',
    duration: 5 * 1000
  })
}

/**
 * @description 显示警告信息
 * @param prompt 提示内容
 * @param annexPrompt 如果提示内容为空，备用提示
 */
message.warningTrim = function(prompt, annexPrompt) {
  Message({
    message: prompt == null || prompt.toString() === 'undefined' || prompt.toString().trim() === '' ? annexPrompt : prompt,
    type: 'warning',
    duration: 5 * 1000
  })
}

/**
 * @description 显示错误信息
 * @param prompt 提示内容
 */
message.error = function(prompt) {
  Message({
    message: prompt,
    type: 'error',
    duration: 5 * 1000
  })
}
/**
 * @description 显示错误信息 (居中)
 * @param prompt 提示内容
 */
message.errors = function(prompt) {
  Message({
    message: prompt,
    type: 'error',
    duration: 2 * 1000,
    offset: 400
  })
}
/**
 * @description 显示错误信息
 * @param prompt 提示内容
 * @param annexPrompt 如果提示内容为空，备用提示
 */
message.errorTrim = function(prompt, annexPrompt) {
  Message({
    message: prompt == null || prompt.toString() === 'undefined' || prompt.toString().trim() === '' ? annexPrompt : prompt,
    type: 'error',
    duration: 5 * 1000
  })
}

export default message
