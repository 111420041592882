import Vue from 'vue'
import VueRouter from 'vue-router'

// 路由数据
import routes from './routes'

Vue.use(VueRouter)

let router = null

export function createRouter() {
  router = new VueRouter({
    routes
  })
}

export function resetRouter() {
  const newRouter = new VueRouter({
    routes
  })
  router.matcher = newRouter.matcher
}

export function destroyRouter() {
  router = null
}

export function getRouter() {
  return router
}

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err)
}

const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

export default router
