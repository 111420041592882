import cookies from './util.cookies'
import db from './util.db'
import log from './util.log'
import validate from './util.validate'
import initialization from './util.initialization'
import calculation from './util.calculation'
import message from './util.message'
import serial from './util.serial'
import api from '@/api/index'
const util = {
  cookies,
  db,
  log,
  validate,
  initialization,
  calculation,
  message,
  serial
}

/**
 * @description 更新标题
 * @param {String} titleText 标题
 */
util.title = function(titleText) {
  const processTitle = process.env.VUE_APP_TITLE
  window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
util.open = function(url) {
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'peacock-link-temp')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('peacock-link-temp'))
}

export default util
