import util from '@/utils/util'
import { MessageBox } from 'element-ui'
import store from '@/store'

/**
 * @description 安全地解析 json 字符串
 * @param {String} jsonString 需要解析的 json 字符串
 * @param {Object} defaultValue 默认值
 */
export function parse(jsonString = '{}', defaultValue = {}) {
  let result = defaultValue
  try {
    result = JSON.parse(jsonString)
  } catch (error) {
    console.log(error)
  }
  return result
}

/**
 * @description 接口请求返回
 * @param returnData 返回值
 * @param {String} returnMsg 状态信息
 * @param {Number} returnCode 状态码
 */
export function response(returnData = {}, returnMsg = '', returnCode = 200) {
  return [200, {
    returnCode,
    returnMsg,
    returnData
  }]
}

/**
 * @description 接口请求返回 正确返回
 * @param returnData 返回值
 * @param {String} returnMsg 状态信息
 */
export function responseSuccess(returnData = {}, returnMsg = '请求成功') {
  return response(returnData, returnMsg)
}

/**
 * @description 接口请求返回 错误返回
 * @param returnData 返回值
 * @param {String} returnMsg 状态信息
 * @param {Number} returnCode 状态码
 */
export function responseError(returnData = {}, returnMsg = '请求失败', returnCode = 500) {
  return response(returnData, returnMsg, returnCode)
}

/**
 * 根据网络错误状态返回错误信息
 * @param {String} status 网络错误状态
 */
export function errorCode(status) {
  switch (status) {
    case 400:
      return '请求失败'
    case 403:
      return '拒绝访问'
    case 404:
      return '请求地址错误'
    case 408:
      return '请求超时'
    case 500:
      return '服务器内部错误'
    case 501:
      return '服务未实现'
    case 502:
      return '网关错误'
    case 503:
      return '服务不可用'
    case 504:
      return '网关超时'
    case 505:
      return 'HTTP版本不受支持'
    default:
      return '请求失败'
  }
}

/**
 * 创建一个错误信息
 * @param {String} msg 错误信息
 */
export function errorCreate(msg) {
  const error = new Error(msg)
  errorLog(error)
  throw error
}

/**
 * @description 记录和显示错误
 * @param {Error} error 错误对象
 */
export function errorLog(error) {
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.log(error)
  }
  // 显示提示
  util.message.error(error.message)
}

let isShowTokenMessage = false

/**
 * @description token过期
 */
export function errorToken() {
  if (isShowTokenMessage) {
    return
  }
  isShowTokenMessage = true
  const error = new Error('TOKEN过期')
  MessageBox.confirm('TOKEN过期，请重新登陆', 'TOKEN过期', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取消',
    type: 'warning',
    closeOnClickModal: false
  }).then(() => {
    isShowTokenMessage = false
    store.dispatch('account/logout')
  }).catch(() => {
    isShowTokenMessage = false
  })
  throw error
}
