export default ({ request, requestSilent }) => ({

  getGoodsHotById(params) {
    return request({
      url: '/goods/getGoodsMsgById',
      method: 'get',
      params
    })
  }


})
