// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import util from '@/utils/util.js'

export function routerAuthority(router) {
  if (!router) {
    return
  }

  router.beforeEach(async(to, from, next) => {
    NProgress.start()
    next()
  })

  router.afterEach(to => {
    NProgress.done()
    // 更改标题
    util.title(to.meta.title)
  })
}
