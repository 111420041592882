export default ({request, requestSilent}) => ({

    resetPwd1(params) {
        return request({
            url: '/sms/password/reset',
            method: 'put',
            params
        })
    },
    /**
     * 手机号验证码验证
     * @param params
     * @returns {*}
     */
    getCodeCheck(params) {
        return request({
            url: '/sms/code/istrue',
            method: 'get',
            params
        })
    },
    /**
     * 获取该手机号下的账号信息
     */
    getAllAccountListByPhone(params) {
        return request({
            url: '/account/getAllAccountListByPhone',
            method: 'get',
            params
        }, process.env.VUE_APP_QUALITY_API)
    },

    /**
     * 更改密码
     */
    resetPwd(params) {
        return request({
            url: '/user/resetPwd',
            method: 'get',
            params
        }, process.env.VUE_APP_QUALITY_API)
    }
})
