import axios from 'axios'
import Adapter from 'axios-mock-adapter'
import { get } from 'lodash'
import util from '@/utils/util'
import { errorCode, errorLog, errorCreate, errorToken } from './tools'

/**
 * @description 创建请求实例
 */
function createService(isSilent, isCancelCheck) {
  const service = axios.create()

  // 请求拦截
  service.interceptors.request.use(
    config => config,
    error => {
      console.log(error)
      return Promise.reject(error)
    }
  )

  // 响应拦截
  service.interceptors.response.use(
    response => {
      const dataAxios = response.data
      if (isCancelCheck) {
        return dataAxios
      } else {
        const { returnCode } = dataAxios
        if (returnCode === undefined) {
          return dataAxios
        } else {
          if (returnCode === 200 || returnCode === '200') {
            return dataAxios
          }

          if (!isSilent) {
            if (returnCode === 10007 || returnCode === '10007' || returnCode === 10006 || returnCode === '10006') {
              errorToken()
            } else {
              errorCreate(dataAxios.returnMsg)
            }
          }
        }
      }
    },
    error => {
      if (!isSilent) {
        error.message = errorCode(get(error, 'response.status'))
        errorLog(error)
      }
      return Promise.reject(error)
    }
  )
  return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function(config, url, noneToken) {
    const configDefault = {
      headers: {
        token: noneToken ? '' : util.cookies.get('token') || '',
        'Content-Type': get(config, 'headers.Content-Type', 'application/json')
      },
      timeout: 60000,
      baseURL: url || process.env.VUE_APP_API,
      data: {}
    }
    return service(Object.assign(configDefault, config))
  }
}

// 用于真实网络请求的实例和请求方法
export const service = createService(false)
export const request = createRequestFunction(service)

// 用于真实网络请求的实例和请求方法(无错误提醒)
export const serviceSilent = createService(true)
export const requestSilent = createRequestFunction(serviceSilent)

// 是否返回所有数据
export const serviceCheck = createService(true, true)
export const requestCheck = createRequestFunction(serviceCheck)
// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService(false)
export const requestForMock = createRequestFunction(serviceForMock)

// 网络请求数据模拟工具
export const mock = new Adapter(serviceForMock)
