import { requestCheck } from '@/api/service'

export default ({ request, requestSilent }) => ({

  checkCompanyName(params) {
    return request({
      url: '/check/check-company-name/' + params,
      method: 'get'
    })
  },
  checkAccountName(params) {
    return request({
      url: '/check/check-account-name/' + params,
      method: 'get'
    })
  },
  // 商户登陆
  getAccountStatus(params) {
    return request({
      url: '/manage/getCompanyStatus/' + params,
      method: 'get'
    }, process.env.VUE_APP_API_ROOT)
  },
  phoneCheck(params) {
    return request({
      url: 'check/check-phone/' + params,
      method: 'get'
    })
  },
  phoneCheck1(params) {
    return request({
      url: '/account/checkPhone',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  registerImg(data) {
    return request({
      url: '/register/img',
      method: 'post',
      data
    })
  },
  authenticationImg(data) {
    return request({
      url: '/authentication/upload',
      method: 'post',
      data
    })
  },
  companyMessageRegister(data) {
    return request({
      url: 'register-company',
      method: 'post',
      data
    }, process.env.VUE_APP_QUALITY_API)
  },
  companyTenantRegister(data) {
    return request({
      url: 'register-companyMessage',
      method: 'post',
      data
    })
  },
  companyRegister(data) {
    return request({
      url: 'register-company',
      method: 'post',
      data
    })
  },
  sendCode(params) {
    return request({
      url: '/sms/code/send',
      method: 'get',
      params
    })
  },
  /**
   * 供应商企业编码判断是否重复
   *
   */
  companyCoding(params) {
    return request({
      url: '/register/checkTenantCode',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 供应商企业编码判断是否重复
   *
   */
  getAccountRefrenceList(params) {
    return request({
      url: '/register/account/getAccountRefrenceList',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 检测供应商企业名称是否重复
   *
   */
  checkTenantName(params1, params2, params3) {
    return requestCheck({
      url: '/register/checkTenantName?companyName=' + params1 + '&tenantType=' + params2 + '&tenantId=' + params3,
      method: 'get'
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 获取销售区域列表
   */
  getSaleAreaList(data) {
    return request({
      url: '/tenant/getSaleAreaList',
      method: 'POST',
      data
    })
  },
  /**
   * 选择注册角色
   */
  setTenantType(params) {
    return request({
      url: '/register/setTenantType',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 产品类型
   */
  getSelKeyWordCategoryList(params) {
    return request({
      url: '/tenant/getSelKeyWordCategoryList',
      method: 'get',
      params
    }, process.env.VUE_APP_API_ROOT)
  },
  /**
   * 产品类型关键词二级
   */
  getSelKeyWordList(params) {
    return request({
      url: '/tenant/getSelKeyWordList',
      method: 'get',
      params
    }, process.env.VUE_APP_API_ROOT)
  }
})
