import { assign, map } from 'lodash'
import faker from 'faker/locale/zh_CN'
import { request, requestSilent, requestForMock, mock, requestCheck } from './service'
import * as tools from './tools'

const files = require.context('./modules', false, /\.js$/)

const generators = files.keys().map(key => files(key).default)

export default assign({}, ...map(generators, generator => generator({
  request,
  requestSilent,
  requestForMock,
  requestCheck,
  mock,
  faker,
  tools
})))
