const verificationRet = {
  // 身份证(精准)
  identityCard: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
  // 身份证(简易)
  // identityCard : /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}\d|[X]{1}$/,
  // 密码
  password: /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{8,16}$/,
  // 手机号
  mobile: /^((1[3|4|5|7|8][0-9]{1})+\d{8})$/,
  // 电子邮箱
  email: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
  // 座机
  landLine: /^0\d{2,3}-?\d{7,8}$/,
  // 数字
  number: /^[0-9]*$/,
  // 银行卡号
  bankCode: /^([1-9]{1})(\d{11}|\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/,
  // 金额
  money: /^(([1-9]\d*)|\d)(\.\d{1,2})?$/
}

const validate = {}

validate.isExternal = function(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @description 非空验证
 * @param source 验证信息
 */
validate.isEmpty = function(source) {
  return source == null || source.toString() === 'undefined' || source.toString() === ''
}

/**
 * @description 去空格后非空验证
 * @param source 验证信息
 */
validate.isEmptyTrim = function(source) {
  return source == null || source.toString() === 'undefined' || source.toString().trim() === ''
}

/**
 * @description 数组非空验证
 * @param source 验证信息
 */
validate.isEmptyArray = function(source) {
  return source == null || source.length === 0
}

/**
 * @description 身份证验证
 * @param identity 验证信息
 */
validate.isIdentity = function(identity) {
  return identity != null && identity !== '' && verificationRet.identityCard.test(identity)
}

/**
 * @description 银行卡验证
 * @param bankCode 验证信息
 */
validate.isBankCode = function(bankCode) {
  return bankCode != null && bankCode !== '' && verificationRet.bankCode.test(bankCode)
}

/**
 * @description 金额验证
 * @param money 验证信息
 */
validate.isMoney = function(money) {
  return money != null && money !== '' && verificationRet.money.test(money)
}

/**
 * @description 邮箱验证
 * @param email 验证信息
 */
validate.isEmail = function(email) {
  return email != null && email !== '' && verificationRet.email.test(email)
}

/**
 * @description 手机号验证
 * @param mobile 验证信息
 */
validate.isMobile = function(mobile) {
  return mobile != null && mobile !== '' && verificationRet.mobile.test(mobile)
}

/**
 * @description 电话验证
 * @param landLine 验证信息
 */
validate.isLandLine = function(landLine) {
  return landLine != null && landLine !== '' && verificationRet.landLine.test(landLine)
}

/**
 * @description 密码验证
 * @param password 验证信息
 */
validate.isPassword = function(password) {
  return password != null && password !== '' && verificationRet.password.test(password)
}

export default validate
