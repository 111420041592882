import calculation from '@/utils/util.calculation'

const serial = {}

/**
 * @description 单页面排序
 * @param index 当前索引
 */
serial.singlePage = function(index) {
  return index + 1
}

/**
 * @description 多页面排序
 * @param index 当前索引
 * @param page 页面
 * @param rows 行数
 */
serial.multiplePage = function(index, page, rows) {
  const num = calculation.mul(page - 1, rows)
  return Number.parseInt(num) + index + 1
}

export default serial
