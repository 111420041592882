import moment from 'moment'

moment.locale('zh-cn')

export default {
  install(Vue, options) {
    // 日期转换
    Vue.prototype.$moment = moment
  }
}
