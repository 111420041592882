export default ({ request, requestSilent }) => ({

    getImgUrl(params) {
        return request({
            url: '/util/img64',
            method: 'get',
            params
        })
    },
    getImgUrl1(params) {
        return request({
            url: '/util/img64',
            method: 'get',
            params
        })
    }

})
