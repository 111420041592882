export default ({ request, requestSilent }) => ({

  getIndexClassifyLists(params) {
    return request({
      url: '/classify/level_0',
      method: 'get',
      params
    })
  },
  getClassifyLists(params) {
    return request({
      url: '/classify/list',
      method: 'get',
      params
    })
  },
  /**
  面料类型与材质接口
   **/
  /**
   *
   * @param params
   * @returns {*}
   */
  // getClassifyLiningTypeLists(params) {
  //   return request({
  //     url: '/fabric/list',
  //     method: 'get',
  //     params
  //   })
  // },
  getClassifyLiningTypeLists(params) {
    return request({
      url: '/fabric/list',
      method: 'get',
      params
    })
  },
  getLists(params) {
    return request({
      url: '/quality-platform/register-company/processingCapacityList',
      method: 'get',
      params
    })
  },
  /**
   二次完善企业信息
   **/
  /**
   *
   * @param params
   * @returns {*}
   */
  completeInformation(params) {
    return request({
      url: '/getTenantDetail/' + params.accountId,
      method: 'get'
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   平台自营
   **/
  /**
   *
   * @param params
   * @returns {*}
   */
  goodsByClassify(params) {
    return request({
      url: '/selfrun/commodity/management/list',
      method: 'get',
      params
    })
  }
})

