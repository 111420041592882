import layoutHeaderAside from '@/layout/index'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/utils/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const routes = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '首页',
          auth: true
        },
        component: _import('index')
      },
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: _import('function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('function/redirect')
      },
      // 跳转商品分类列表
      {
        path: 'topClassifyNav',
        name: 'topClassifyNav',
        hidden: true,
        component: _import('topClassifyNav')
      },
      // 跳转搜索工厂
      {
        path: 'searchFactory',
        name: 'searchFactory',
        hidden: true,
        component: _import('searchFactory')
      },
      // 跳转立即询价页面
      {
        path: 'immedInquiry',
        name: 'immedInquiry',
        hidden: true,
        component: _import('immedInquiry')
      },
      // 跳转商户注册
      {
        path: 'tenantRegister',
        name: 'tenantRegister',
        hidden: true,
        component: _import('tenantRegister')
      },
      // 跳转商户登录
      {
        path: 'tenantLogin',
        name: 'tenantLogin',
        hidden: true,
        component: _import('tenantLogin')
      },
      // 跳转供应商注册
      {
        path: 'supplierRegister',
        name: 'supplierRegister',
        hidden: true,
        component: _import('supplierRegister')
      },
      // 跳转供应商登录
      {
        path: 'supplierLogin',
        name: 'supplierLogin',
        hidden: true,
        component: _import('supplierLogin')
      },
      // 跳转企业管理中学
      {
        path: 'managerCenter',
        name: 'managerCenter',
        hidden: true,
        component: _import('managerCenter')
      },
      // 跳转登陆注册页面
      {
        path: 'loginChoose',
        name: 'loginChoose',
        hidden: true,
        component: _import('loginChoose')
      },
      // 跳转在线演示页面
      {
        path: 'tenantOnlineUseraction',
        name: 'tenantOnlineUseraction',
        hidden: true,
        component: _import('tenantOnlineUseraction')
      },
        //跳转在线演示
      {
        path: 'supplierOnlineUseraction',
        name: 'supplierOnlineUseraction',
        hidden: true,
        component: _import('supplierOnlineUseraction')
      },
      //在线演示登陆
      {
        path: 'loginOnLine',
        name: 'loginOnLine',
        hidden: true,
        component: _import('loginOnLine')
      },
      // 跳转工厂简介
      {
        path: 'serviceGuide',
        name: 'serviceGuide',
        hidden: true,
        component: _import('serviceGuide')
      },
      // 跳转需求广场
      {
        path: 'demandMore',
        name: 'demandMore',
        hidden: true,
        component: _import('demandMore')
      },
      // 跳转发布需求
      {
        path: 'demandRelease',
        name: 'demandRelease',
        hidden: true,
        component: _import('demandRelease')
      },
      // 跳转查看详情
      {
        path: 'demandDetail',
        name: 'demandDetail',
        hidden: true,
        component: _import('demandDetail')
      },
      // 热门商品详情
      {
        path: 'hotProductDetail',
        name: 'hotProductDetail',
        hidden: true,
        component: _import('hotProductDetail')
      },
      // 跳转忘记密码
      {
        path: 'forgotPassword',
        name: 'forgotPassword',
        hidden: true,
        component: _import('forgotPassword')
      },
      {
        path: 'managerCenter',
        name: 'managerCenter',
        hidden: true,
        component: _import('managerCenter')
      },
      // 微信登陆成功页面
      {
        path: 'weChatLogin',
        name: 'weChatLogin',
        hidden: true,
        component: _import('weChatLogin')
      }

    ]
  },
  {
    path: '*',
    name: '404',
    component: _import('error')
  }
]

// 重新组织后导出
export default [
  ...routes
]
