const initialization = {}

/**
 * @description 初始化json表单数据
 */
initialization.init = function(data) {
  if (Object.prototype.toString.call(data) !== '[object Object]') {
    return
  }

  for (const key in data) {
    const type = Object.prototype.toString.call(data[key])
    switch (type) {
      case '[object String]':
        data[key] = ''
        break
      case '[object Number]':
        data[key] = 0
        break
      case '[object Boolean]':
        data[key] = false
        break
      case '[object Date]':
        data[key] = null
        break
      case '[object Array]':
        data[key] = []
        break
      case '[object Object]':
        initialization.init(data[key])
        break
    }
  }
}

export default initialization
