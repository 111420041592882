<template>
  <div id="peacock" style="height: auto">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  // 首页 弹窗 控制出现一次
  mounted() {
    /* 区分关闭和刷新，关闭退出登录 start*/
    window.onload = function() {
      if (!window.sessionStorage['tempFlag']) {
        localStorage.removeItem('IS_DIALOG') // 关闭页面时清空
        location.reload() // 不能省，强制跳到登陆页
      } else {
        window.sessionStorage.removeItem('tempFlag')
      }
    }
    window.onunload = function() {
      window.sessionStorage['tempFlag'] = true
    }
    window.onbeforeunload = function() {
      window.sessionStorage['tempFlag'] = true
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/public-class.scss";
</style>
