// Vue
import Vue from 'vue'
import App from './App'
// 核心插件
import plugin from '@/plugin'
// store
import store from '@/store/index'
// 菜单和路由设置
import { createRouter, getRouter } from '@/router/index'
import { routerAuthority } from '@/router/authority'
import VueResource from 'vue-resource'

// 核心插件
Vue.use(plugin)
Vue.use(VueResource)

createRouter()
const router = getRouter()
routerAuthority(router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#peacock')

const prefix = process.env.PREFIX + '_'
window.$sessionStorage = {
  setItem(key, value) {
    sessionStorage.setItem(prefix + key, value)
  },
  getItem(key) {
    return sessionStorage.getItem(prefix + key)
  },
  removeItem(key) {
    sessionStorage.removeItem(prefix + key)
  },
  clear() {
    const rm_k = []
    for (const k in sessionStorage) {
      if (k.indexOf(prefix) > -1) {
        sessionStorage.removeItem(k)
      }
    }
    for (const i in rm_k) {
      sessionStorage.removeItem(rm_k[i])
    }
  }
}
Vue.prototype.setAccountPermissions = function(p) {
  window.$permissions = p
}

