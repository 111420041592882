// Element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// flex 布局库
import 'flex.css'

// 组件
import '@/components'

// svg 图标
import '@/assets/svg-icons'

// 功能插件
import pluginApi from '@/plugin/api'
import pluginLog from '@/plugin/log'
import pluginMoment from '@/plugin/moment'
import pluginOpen from '@/plugin/open'
import pluginKey from '@/plugin/key'
import pluginUtil from '@/plugin/util'

export default {
  async install(Vue) {
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    Vue.config.productionTip = false
    // 当前环境
    Vue.prototype.$env = process.env.NODE_ENV
    // 当前版本
    Vue.prototype.$version = process.env.VUE_APP_VERSION
    // 构建时间
    Vue.prototype.$buildTime = process.env.VUE_APP_BUILD_TIME
    // api
    Vue.prototype.$manageApi = process.env.VUE_APP_API
    // api
    Vue.prototype.$qualityApi = process.env.VUE_APP_QUALITY_API
    // 商户
    window.tenantUrl = process.env.VUE_APP_TENANT_URL
    // 供应商
    window.supplierUrl = process.env.VUE_APP_SUPPLIER_URL
    // Element
    Vue.use(ElementUI)

    // 插件
    Vue.use(pluginApi)
    Vue.use(pluginLog)
    Vue.use(pluginMoment)
    Vue.use(pluginOpen)
    Vue.use(pluginKey)
    Vue.use(pluginUtil)
  }
}
