import { Decimal } from 'decimal.js'

const calculation = {}

/**
 * @description 准确加法
 * @param arg1 数值
 * @param arg2 数值
 */
calculation.add = function(arg1, arg2) {
  return new Decimal(arg1).add(new Decimal(arg2))
}

/**
 * @description 准确减法
 * @param arg1 数值
 * @param arg2 数值
 */
calculation.sub = function(arg1, arg2) {
  return new Decimal(arg1).sub(new Decimal(arg2))
}

/**
 * @description 准确乘法
 * @param arg1 数值
 * @param arg2 数值
 */
calculation.mul = function(arg1, arg2) {
  return new Decimal(arg1).mul(new Decimal(arg2))
}

/**
 * @description 准确除法
 * @param arg1 数值
 * @param arg2 数值
 */
calculation.div = function(arg1, arg2) {
  return new Decimal(arg1).div(new Decimal(arg2))
}

export default calculation
