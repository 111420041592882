export default ({ request, requestSilent }) => ({

  tenantLogin(data) {
    return request({
      url: '/login-foreground',
      method: 'post',
      data
    }, process.env.VUE_APP_API_ROOT, true)
  },

  /**
   * 供应商登录判断二次完善信息
   *
   */
  suppLierLogin(data) {
    return request({
      url: '/login',
      method: 'post',
      data
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 在线演示供应商登陆
   *
   */
  judgeStatus(id) {
    return request({
      url: '/manage/getCompanyStatus/' + id,
      method: 'get'
    }, process.env.VUE_APP_API_ROOT)
  },
  /**
   * 获取微信二维码地址
   *
   */
  weChatLogin() {
    return request({
      url: '/wxPage/login',
      method: 'get'
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 获取图形验证码
   *
   */
  getPatterningCode() {
    return request({
      url: '/captcha/get',
      method: 'get',
      responseType: 'blob'
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 图形验证码验证
   *
   */
  verificationIdentifyingCode(params) {
    return request({
      url: '/captcha/check',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 手机号登录
   *
   */
  loginByPhone(params) {
    return request({
      url: '/loginByPhone',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 根据扫码跳转返回的code进行登录
   *
   */
  loginByCode(params) {
    return request({
      url: '/wxPage/loginByCode',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 手机号获取账户信息
   *
   */
  getAccountListByPhone(params) {
    return request({
      url: '/wxPage/getAccountListByPhone',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  },
  /**
   * 微信扫码登陆后用户信息绑定
   *
   */
  bindWxUserInfo(params) {
    return request({
      url: '/wxPage/bindWxUserInfo',
      method: 'get',
      params
    }, process.env.VUE_APP_QUALITY_API)
  }
})
